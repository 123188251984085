import ApiService from "@/core/services/ApiService";
import { SubmissionData } from "@/core/types/gws-agent/submission/SubmissionData";
import { CpmiData } from "@/core/types/gws-agent/cpmi-detail/CpmiData";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { MasterCpmiStatus } from "@/core/config/MasterCpmiStatus";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import { FilterData } from "@/core/types/misc/FilterData";
import PaginationType from "@/core/types/misc/Pagination";

export const usePolriSubmissionSkckListStore = defineStore({
  id: "polriSubmissionSkckListStore",
  state: () => {
    return {
      stateLoading: false,
      stateError: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "ASC",
        sort: "created_at",
      } as FilterData,
      statePagination: new PaginationType(),
      stateDisplayData: [] as SubmissionData[],
      stateFormData: [] as SubmissionData[],
    };
  },
  getters: {
    loading(state) {
      return state.stateLoading;
    },
    displayData(state) {
      return state.stateFormData;
    },
    error(state) {
      return state.stateError;
    },
  },
  actions: {
    async getSkck(isPageRefresh = true) {
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      if (this.stateFilterData.search) {
        params["search"] = this.stateFilterData.search;
      }
      if (this.stateFilterData.status) {
        params["status"] = this.stateFilterData.status;
      }
      try {
        if (isPageRefresh) this.stateLoading = true;
        const response: AxiosResponse<ApiResponse<SubmissionData[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `skck?${new URLSearchParams(params).toString()}`
          );
        this.stateDisplayData = response.data.data;
        this.stateFormData = response.data.data;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateLoading = false;
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getSkck();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getSkck();
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
